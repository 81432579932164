import bind from '@123/druid/dist/Utility/Decorator/Bind';
import type SliderLayout from '@Component/Slider/SliderLayout';
import type SliderUI from '@Component/Slider/SliderUI';
import type TrackController from '@Component/Slider/TrackController';

export default class ResizeHandler {
    private isAttached = false;

    constructor(
        private readonly sliderUI: SliderUI,
        private readonly sliderLayout: SliderLayout,
        private readonly trackController: TrackController,
    ) {}

    public attach(): this {
        if (this.isAttached) {
            throw new Error('ResizeHandler was already attached.');
        }

        window.addEventListener('resize', this.handleResize);

        this.isAttached = true;
        return this;
    }

    public detach(): void {
        if (this.isAttached === false) {
            throw new Error('FocusHandler was not attached.');
        }

        window.removeEventListener('resize', this.handleResize);
        this.isAttached = false;
    }

    @bind
    private handleResize(): void {
        if (this.sliderUI.slides.length === 0) {
            return;
        }

        this.sliderLayout.updateLayout();
        void this.trackController.showActivePage(true);
    }
}
