import type SliderLayout from '@Component/Slider/SliderLayout';
import type SliderUI from '@Component/Slider/SliderUI';
import type TrackController from '@Component/Slider/TrackController';
import * as defaults from '@Component/Slider/FixedSliderDefaultValues';
import type {SliderEventNames} from '@Component/Slider/Types/SliderEvents';

// setting constants for strings that occur often, for slightly improved minification
const slidesVisible = 'slides-visible';
const gap = 'gap';
const orientation = 'orientation';
const align = 'align-to';
const activePageIndex = 'active-page';
const slidesPerPage = 'slides-per-page';

export default class AttributeHandler {
    public static readonly observedAttributes = [slidesVisible, gap, orientation, activePageIndex, align, slidesPerPage];

    public static readonly UPDATE = 'update';
    public static readonly UPDATE_PAGE = 'update_page';
    public static readonly UPDATE_SLIDES_VISIBLE = 'update_slides_visible';

    constructor(
        private readonly ui: SliderUI,
        private readonly layout: SliderLayout,
        private readonly trackController: TrackController,
    ) {}

    public handleAttribute(name: string, newValue: string | null, oldValue: string | null): void {
        switch (name) {
            case slidesVisible:
                this.handleSlidesVisible(newValue, oldValue);
                break;
            case gap:
                this.handleGap(newValue);
                break;
            case orientation:
                this.handleOrientation(newValue);
                break;
            case align:
                this.handleAlign(newValue);
                break;
            case activePageIndex:
                this.handleActivePageIndex(newValue, oldValue);
                break;
            case slidesPerPage:
                this.handleSlidesPerPage(newValue);
                break;
        }
    }

    private handleSlidesVisible(newValue: string | null, oldValue: string | null): void {
        if (newValue !== null && isNaN(parseFloat(newValue))) {
            this.ui.element.slidesVisible = defaults.slidesVisible;
            return;
        }
        this.layout.updateLayout();
        void this.trackController.showActivePage(true);
        this.sendEvent(AttributeHandler.UPDATE);

        if (newValue !== oldValue) {
            this.sendEvent(AttributeHandler.UPDATE_SLIDES_VISIBLE);
        }
    }

    private handleGap(newValue: string | null): void {
        if (newValue !== null && isNaN(parseFloat(newValue))) {
            this.ui.element.gap = defaults.gap;
            return;
        }
        this.layout.updateLayout();
        this.sendEvent(AttributeHandler.UPDATE);
    }

    private handleOrientation(newValue: string | null): void {
        if (newValue !== null && newValue !== 'v' && newValue !== 'h') {
            this.ui.element.orientation = defaults.orientation;
            return;
        }
        this.layout.updateLayout();
        void this.trackController.showActivePage(true);
        this.sendEvent(AttributeHandler.UPDATE);
    }

    private handleAlign(newValue: string | null): void {
        if ([null, 'start', 'end', 'center'].includes(newValue) === false) {
            this.ui.element.alignTo = defaults.align;
            return;
        }
        void this.trackController.showActivePage(true);
        this.sendEvent(AttributeHandler.UPDATE);
    }

    private handleActivePageIndex(newValue: string | null, oldValue: string | null): void {
        if (newValue !== null && isNaN(parseInt(newValue))) {
            this.ui.element.activePage = defaults.activePage;
            return;
        }
        void this.trackController.showActivePage();
        this.sendEvent(AttributeHandler.UPDATE);

        if (newValue !== oldValue) {
            this.sendEvent(AttributeHandler.UPDATE_PAGE);
        }
    }

    private handleSlidesPerPage(newValue: string | null): void {
        if (newValue !== null && isNaN(parseInt(newValue))) {
            this.ui.element.slidesPerPage = defaults.slidesPerPage;
            return;
        }
        void this.trackController.showActivePage();
        this.sendEvent(AttributeHandler.UPDATE);
    }

    private sendEvent(eventName: SliderEventNames): void {
        this.ui.element.dispatchEvent(new CustomEvent(eventName, {bubbles: true, cancelable: true}));
    }
}
