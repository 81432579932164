import Point from '@123/druid/dist/Utility/Point';
import type SliderLayout from '@Component/Slider/SliderLayout';
import type SliderUI from '@Component/Slider/SliderUI';
import {type SliderEventMap} from '@Component/Slider/Types/SliderEvents';
import {gsap} from 'gsap';
import raise from '@123/druid/dist/Utility/Raise';

export default class TrackController {
    private static readonly DRAG_TIME_THRESHOLD = 500;
    private static readonly DRAG_DISTANCE_THRESHOLD = 10;

    private animation?: gsap.core.Animation;

    private isDragging = false;
    private dragStartPos = Point.ZERO;
    private dragTrackStartPos = Point.ZERO;
    private dragStartTime = -1;

    constructor(
        private readonly ui: SliderUI,
        private readonly layout: SliderLayout,
    ) {}

    public showActivePage(immediate = false): Promise<void> {
        if (this.ui.slides.length === 0 || this.isDragging) {
            return Promise.reject();
        }

        const {x, y} = this.getTrackTarget();

        if (this.animation?.isActive()) {
            this.animation.kill();
        }

        return new Promise((resolve) => {
            if (immediate === false) {
                // px per second
                const speed = this.ui.element.transitionSpeed;
                const currentX = <number>gsap.getProperty(this.ui.track, 'x');
                const timeInS = Math.abs(currentX - x) / speed;

                this.ui.element.dispatchEvent(this.createEvent('startTransition'));
                this.animation = gsap
                    .timeline({
                        onComplete: () => {
                            this.ui.element.dispatchEvent(this.createEvent('endTransition'));
                            resolve();
                        },
                    })
                    .to(this.ui.track, {x, y, duration: timeInS, ease: 'sine.inOut'});
                return;
            }

            gsap.set(this.ui.track, {x, y});
            resolve();
        });
    }

    public startDrag(pos: Point): void {
        if (this.isDragging) {
            return;
        }

        this.dragStartPos = pos;
        this.isDragging = true;
        this.dragStartTime = Date.now();
        this.dragTrackStartPos = this.getCurrentPosition();
    }

    public updateDrag(pos: Point): void {
        if (this.isDragging === false) {
            return;
        }

        const orientation = this.ui.element.orientation;
        const translateX = orientation === 'h' ? pos.x - this.dragStartPos.x : 0;
        const translateY = orientation === 'v' ? pos.y - this.dragStartPos.y : 0;
        gsap.set(this.ui.track, {x: this.dragTrackStartPos.x + translateX, y: this.dragTrackStartPos.y + translateY});
    }

    public endDrag(): void {
        if (this.isDragging === false) {
            return;
        }

        const timePassed = Date.now() - this.dragStartTime;
        const dragTrackEndPos = this.getCurrentPosition();
        const distance =
            this.ui.element.orientation === 'h' ? dragTrackEndPos.x - this.dragTrackStartPos.x : dragTrackEndPos.y - this.dragTrackStartPos.y;

        this.isDragging = false;

        let moved = false;
        // if we're in the "gesture" threshold (enough distance and within the time threshold)
        if (Math.abs(distance) > TrackController.DRAG_DISTANCE_THRESHOLD && timePassed <= TrackController.DRAG_TIME_THRESHOLD) {
            if (distance < 0) {
                moved = this.ui.element.nextPage();
            } else {
                moved = this.ui.element.prevPage();
            }
        }

        // if we haven't moved by now, always reset/set to the cloesest page
        if (moved === false) {
            this.ui.element.activePage = this.layout.getClosestPage(
                <number>gsap.getProperty(this.ui.track, 'x'),
                <number>gsap.getProperty(this.ui.track, 'y'),
            );
        }
    }

    /**
     * returns the x,y translation for the track to get the active page on the proper alignment.
     * @private
     */
    // eslint-disable-next-line max-lines-per-function
    private getTrackTarget(): Point {
        const pageIndex = this.ui.element.activePage;
        const orientation = this.ui.element.orientation;
        const sliderSize = this.layout.getSliderSize();
        const slidesInPage = this.layout.getSlidesForPage(pageIndex);
        if (slidesInPage.length === 0) {
            throw new Error('Not enough slides');
        }

        const pageBounds = {
            top: 0,
            left: 0,
            right: sliderSize.width,
            bottom: sliderSize.height,
            width: sliderSize.width,
            height: sliderSize.height,
        };
        // ts does not know that there should always be at least one element in this array. So, we have to raise to make ts happy.
        const firstSlide = slidesInPage[0] ?? raise('Not enough slides');
        const lastSlide = slidesInPage[slidesInPage.length - 1] ?? raise('Not enough slides');

        if (orientation === 'h') {
            pageBounds.left = parseFloat(window.getComputedStyle(firstSlide).left);
            pageBounds.right = parseFloat(window.getComputedStyle(lastSlide).left) + parseFloat(window.getComputedStyle(lastSlide).width);
            pageBounds.width = pageBounds.right - pageBounds.left;
        } else {
            pageBounds.top = parseFloat(window.getComputedStyle(firstSlide).top);
            pageBounds.bottom = parseFloat(window.getComputedStyle(lastSlide).top) + parseFloat(window.getComputedStyle(lastSlide).height);
            pageBounds.height = pageBounds.bottom - pageBounds.top;
        }

        // default values are for 'alignTo: start'
        const pageFocus = {x: pageBounds.left, y: pageBounds.top};
        const wDiff = sliderSize.width - pageBounds.width;
        const hDiff = sliderSize.height - pageBounds.height;

        if (this.ui.element.alignTo === 'center') {
            pageFocus.x -= orientation === 'h' ? wDiff / 2 : 0;
            pageFocus.y -= orientation === 'h' ? 0 : hDiff / 2;
        } else if (this.ui.element.alignTo === 'end') {
            pageFocus.x -= orientation === 'h' ? wDiff : 0;
            pageFocus.y -= orientation === 'h' ? 0 : hDiff;
        }

        return new Point(-pageFocus.x, -pageFocus.y);
    }

    private createEvent(type: keyof SliderEventMap): CustomEvent {
        return new CustomEvent<{index: number}>(type, {
            bubbles: true,
            cancelable: true,
            detail: {index: this.ui.element.activePage},
        });
    }

    private getCurrentPosition(): Point {
        const style = window.getComputedStyle(this.ui.track);
        const matrix = new DOMMatrix(style.transform);
        return new Point(matrix.m41, matrix.m42);
    }
}
