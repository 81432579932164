import type SliderControls from '@Component/Carousel/Component/SliderControls';

export default class SliderControlsUI {
    public prevButton?: HTMLElement;
    public nextButton?: HTMLElement;
    public dotsContainer?: HTMLElement;

    constructor(public readonly element: SliderControls) {
        this.prevButton = element.queryRole<SliderControls>('prev-slide');
        this.nextButton = element.queryRole<SliderControls>('next-slide');
        this.dotsContainer = element.queryRole<SliderControls>('slider-pages');
    }
}
