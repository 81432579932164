import type SliderUI from '@Component/Slider/SliderUI';
import raise from '@123/druid/dist/Utility/Raise';

interface Size {
    width: number;
    height: number;
}

export default class SliderLayout {
    private slideSize: Size = {width: 0, height: 0};

    constructor(private readonly ui: SliderUI) {}

    public addSlide(slideElement: HTMLElement, atIndex?: number): number {
        if (this.ui.slides.includes(slideElement) === true) {
            return -1;
        }

        this.ui.track.appendChild(slideElement);

        slideElement.datamap().setString('role', 'slide element-list-item');

        if (atIndex === undefined) {
            this.ui.slides.push(slideElement);
            atIndex = this.ui.slides.length - 1;
        } else {
            this.ui.slides.splice(atIndex, 0, slideElement);
        }

        this.updateLayout();
        return atIndex;
    }

    /**
     * Removes a slide from the slider.
     * @param {HTMLElement} slideElement
     * @returns {boolean} - true if this slide is part of the slider, false if it isn't.
     */
    public removeSlide(slideElement: HTMLElement): boolean {
        if (this.ui.slides.includes(slideElement) === false) {
            return false;
        }
        this.ui.track.enableElement(slideElement, false);
        this.updateLayout();
        return true;
    }

    /**
     * Returns the slides (HTMLElements) that are part of a page in the slider
     * @param {number} pageIndex
     * @returns {HTMLElement[]}
     */
    public getSlidesForPage(pageIndex: number): HTMLElement[] {
        const slidesPerPage = this.ui.element.slidesPerPage;
        if (slidesPerPage >= this.ui.slides.length) {
            return this.ui.slides.slice(0);
        }

        const requestedFirstSlide = pageIndex * slidesPerPage;
        let actualFirstSlide = requestedFirstSlide;
        if (requestedFirstSlide + slidesPerPage > this.ui.slides.length) {
            actualFirstSlide = this.ui.slides.length - slidesPerPage;
        }

        return this.ui.slides.slice(actualFirstSlide, actualFirstSlide + slidesPerPage);
    }

    /**
     * Returns the slides (HTMLElements) that are visible in the slider
     * @returns {HTMLElement[]}
     */
    public getVisibleSlides(): HTMLElement[] {
        const startIndex = this.ui.element.slidesPerPage * this.ui.element.activePage;
        const endIndex = startIndex + this.ui.element.slidesVisible;

        return this.ui.slides.slice(startIndex, endIndex);
    }

    /**
     * Based on the amount of slides, and the slides-per-page setting, returns the number of pages in the slider
     * @returns {number}
     */
    public getPageCount(): number {
        return Math.ceil(this.ui.slides.length / this.ui.element.slidesPerPage);
    }

    public getSlideSize(): Readonly<Size> {
        return this.slideSize;
    }

    public getSliderSize(): Readonly<Size> {
        return {
            width: this.ui.element.getWidth('inner'),
            height: this.ui.element.getHeight('inner'),
        };
    }

    /**
     * Based on the current offset of the track, returns the page index that is closest to it. Takes the orientation of the slider into account.
     * @param trackPositionX
     * @param trackPositionY
     * @returns {number}
     */
    public getClosestPage(trackPositionX: number, trackPositionY: number): number {
        const maxPages = this.getPageCount();
        const orient = this.ui.element.orientation;
        const gap = this.ui.element.gap;
        const relevantTrackPosition = orient === 'h' ? trackPositionX : trackPositionY;

        let bestPage = 0;
        let currentDistance = Number.MAX_VALUE;

        let focus = 0;
        if (this.ui.element.alignTo === 'center') {
            focus = orient === 'h' ? this.getSliderSize().width / 2 : this.getSliderSize().height / 2;
        } else if (this.ui.element.alignTo === 'end') {
            focus = orient === 'h' ? this.getSliderSize().width : this.getSliderSize().height;
        }

        for (let pageIndex = 0; pageIndex < maxPages; pageIndex++) {
            const slides = this.getSlidesForPage(pageIndex);
            const firstSlide = slides[0] ?? raise('Not enough slides');
            const lastSlide = slides[slides.length - 1] ?? raise('Not enough slides');
            const pageStart = -gap + parseFloat(orient === 'h' ? firstSlide.style.left : firstSlide.style.top);
            const pageEnd =
                gap +
                parseFloat(orient === 'h' ? lastSlide.style.left : lastSlide.style.top) +
                parseFloat(orient === 'h' ? lastSlide.style.width : lastSlide.style.height);
            const pageCenter = pageStart + (pageEnd - pageStart) / 2;

            const distance = Math.abs(relevantTrackPosition - focus + pageCenter);
            if (distance < currentDistance) {
                bestPage = pageIndex;
                currentDistance = distance;
            }
        }

        return bestPage;
    }

    // @debounce(60)
    public updateLayout(): void {
        const {width, height} = this.getSliderSize();

        const gap = this.ui.element.gap;
        const slidesVisible = this.ui.element.slidesVisible;
        const orientation = this.ui.element.orientation;

        const amountGaps = Math.floor(slidesVisible) - 1;

        this.slideSize = {
            width: orientation === 'h' ? (width - gap * amountGaps) / slidesVisible : width,
            height: orientation === 'v' ? (height - gap * amountGaps) / slidesVisible : height,
        };

        this.ui.slides.forEach((slideElem, index) => {
            const leftPos = orientation === 'h' ? index * gap + index * this.slideSize.width : 0;
            const topPos = orientation === 'v' ? index * gap + index * this.slideSize.height : 0;

            slideElem.style.width = `${this.slideSize.width}px`;
            slideElem.style.height = `${this.slideSize.height}px`;
            slideElem.style.left = `${leftPos}px`;
            slideElem.style.top = `${topPos}px`;
        });
    }
}
