import * as defaults from '@Component/ElementList/ElementListDefaultValues';
import type ElementListUI from '@Component/ElementList/ElementListUI';

const maxItems = 'max-items';

export default class AttributeHandler {
    public static readonly observedAttributes = [maxItems];

    constructor(private readonly ui: ElementListUI) {}

    public handleAttribute(name: string, newValue: string | null): void {
        switch (name) {
            case maxItems:
                if (newValue !== null && isNaN(parseInt(newValue))) {
                    this.ui.element.maxItems = defaults.maxItems;
                }
                this.ui.updateElements();
                break;
        }
    }
}
