import type SliderControlsUI from '@Component/Carousel/Component/Type/SliderControlsUI';
import type {SliderPageInfo} from '@Component/Slider/Types/SliderInfo';

export default class SliderControlsLayout {
    private pages = 0;

    constructor(private readonly ui: SliderControlsUI) {}

    public createPaginator(pageInfo: SliderPageInfo): void {
        if (pageInfo.pageCount === this.pages || this.ui.dotsContainer === undefined) {
            return;
        }

        this.ui.dotsContainer.innerHTML = '';

        for (let i = 0; i < pageInfo.pageCount; i++) {
            this.ui.dotsContainer.append(this.createPageButton(i));
        }

        this.pages = pageInfo.pageCount;
    }

    public setPage(pageInfo: SliderPageInfo): void {
        if (this.ui.dotsContainer === undefined) {
            return;
        }

        const pages = Array.from(this.ui.dotsContainer.children);
        pages.forEach((element) => element.classList.remove('c-slider__dot--active'));
        pages[pageInfo.activePage]?.classList.add('c-slider__dot--active');

        this.setArrows(pageInfo);
    }

    private createPageButton(page: number): HTMLElement {
        const button = document.createElement('button');
        button.dataset.role = 'dot';
        button.dataset.page = page.toString();
        button.classList.add('c-slider__dot');
        button.innerText = '•';

        return button;
    }

    private setArrows(pageInfo: SliderPageInfo): void {
        if (this.ui.prevButton === undefined || this.ui.nextButton === undefined) {
            return;
        }

        [this.ui.prevButton, this.ui.nextButton].forEach((el) => el.classList.remove('c-slider__arrow--hidden'));

        if (pageInfo.onFirstPage) {
            this.ui.prevButton.classList.add('c-slider__arrow--hidden');
        }
        if (pageInfo.onLastPage) {
            this.ui.nextButton.classList.add('c-slider__arrow--hidden');
        }
    }
}
