import bind from '@123/druid/dist/Utility/Decorator/Bind';
import type SliderLayout from '@Component/Slider/SliderLayout';
import type SliderUI from '@Component/Slider/SliderUI';
import type TrackController from '@Component/Slider/TrackController';

export default class TrackHandler {
    private isAttached = false;

    constructor(
        private readonly sliderUI: SliderUI,
        private readonly sliderLayout: SliderLayout,
        private readonly trackController: TrackController,
    ) {}

    public attach(): this {
        if (this.isAttached) {
            throw new Error('TrackUpdateHandler was already attached.');
        }

        this.sliderUI.track.addEventListener('mounted', this.handleMount);
        this.sliderUI.track.addEventListener('update', this.handleUpdate);

        this.isAttached = true;
        return this;
    }

    public detach(): void {
        if (this.isAttached === false) {
            throw new Error('TrackUpdateHandler was not attached.');
        }

        this.sliderUI.track.removeEventListener('update', this.handleUpdate);
        this.isAttached = false;
    }

    @bind
    private handleUpdate(): void {
        this.toggleDisplay();
        if (this.sliderUI.slides.length === 0) {
            return;
        }

        this.sliderLayout.updateLayout();
        void this.trackController.showActivePage(true);
    }

    @bind
    private handleMount(): void {
        this.handleUpdate();

        // slides will have no size until updateLayout was called at least once. Adding .initialized will reveal the track
        this.sliderUI.element.classList.add('u-initialized');
    }

    private toggleDisplay(): void {
        if (this.sliderUI.slides.length === 0) {
            this.sliderUI.element.style.height = '0';
        } else {
            this.sliderUI.element.style.removeProperty('height');
        }
    }
}
