import type QuickFilterInput from '@Module/Group/Component/QuickFilter/WebComponent/QuickFilterInput';

export default class QuickFilterUI {
    public readonly inputElement: HTMLInputElement;
    public readonly resetButton: HTMLElement;

    constructor(public readonly element: QuickFilterInput) {
        this.inputElement = element.queryRole<HTMLInputElement>('quickfilter-input');
        this.resetButton = element.queryRole<HTMLElement>('quickfilter-reset');
    }
}
