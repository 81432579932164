import type ElementListCollapser from '@Component/ElementList/Component/ElementListCollapser';
import type ElementList from '@Component/ElementList/ElementList';

export default class CollapserHandler {
    private collapser?: ElementListCollapser;
    private initMaxItems: number = 0;

    constructor(private readonly element: ElementList) {
        void element.queryRolePromise<ElementListCollapser>('element-list-collapser').then((collapser) => {
            this.collapser = collapser;
            this.initMaxItems = element.maxItems;
            this.attach();
        });
    }

    public detachHandler(): void {
        this.detach();
    }

    private attach(): void {
        if (this.collapser === undefined) {
            return;
        }

        this.collapser.addEventListener('collapse', () => (this.element.maxItems = -1));
        this.collapser.addEventListener('uncollapse', () => (this.element.maxItems = this.initMaxItems));
    }

    private detach(): void {
        if (this.collapser === undefined) {
            return;
        }

        this.collapser.removeEventListeners('collapse uncollapse');
    }
}
