/* eslint-disable import/first */
import Druid from '@123/druid';

Druid.configure({
    form: {
        elements: ['input', 'textarea', 'select'],
        fileInputs: Druid.DEFAULT_FILE_INPUTS,
        className: 'dr-form',
        invalidInputClass: 'dr-form--invalid-input',
    },
    dialog: {
        frameTemplate: `
            <div class="dr-dialog-frame" data-test="popup">
                <div class="dr-header" data-role="header">
                    <span class="dr-title" data-role="title"></span>
                    <span class="dr-close" data-role="close"></span>
                </div>
                <div class="dr-wrapper">
                    <div class="dr-content" data-role="content"></div>
                    <div class="dr-footer" data-role="footer"></div>
                </div>
            </div>`,
    },
    translations: {
        mandatoryField: '%%LANG_MANDATORY%%',
        errorInputInvalid: '%%LANG_ERROR%%',
    },
});

Druid.addDefaultRequestHeader('X-Requested-With', 'XMLHttpRequest');

import ViewFinder from '@123/druid/dist/Framework/View/ViewFinder';
import ApplicationView from '@Application/ApplicationView';
// start the ApplicationView
new ViewFinder(Druid.getViewFramework()).getView(document.body, ApplicationView);

/* include WebComponents */
import '@Module/Group/Component/QuickFilter/WebComponent/QuickFilterInput';
import '@Component/Carousel/Component/SliderControls';
import '@Component/Slider/FixedSlider';
import '@Component/Carousel/Carousel';
import '@Component/ElementList/Component/ElementListCollapser';
import '@Component/ElementList/ElementList';
import '@Component/Dropdown/Dropdown';

/* include injectables */
import '@Service/Scrollable/Scrollable';
import '@Application/ApplicationConfig';
import '@Service/Message/MessageBus';
