import component from '@123/druid/dist/Framework/Decorators/Component';
import {inject} from '@123/druid/dist/Framework/Decorators/Inject';
import View from '@123/druid/dist/Framework/View/View';
import ApplicationConfig from '@Application/ApplicationConfig';
import HeaderView from '@Application/Header/View/HeaderView';

@component
export default class ApplicationView extends View {
    constructor(@inject(ApplicationConfig) private readonly applicationConfig: ApplicationConfig) {
        super();
    }

    protected mounted(): void {
        void this.el.queryRolePromise('scroll-limit-top').then((element) => this.applicationConfig.setHeader(element));

        this.getViewOrNull(HeaderView);
    }
}
