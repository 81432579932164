import type SliderControlsUI from '@Component/Carousel/Component/Type/SliderControlsUI';
import type {SlideDetails} from '@Component/Carousel/Component/Type/SliderEvent';

export default class ClickHandler {
    constructor(private readonly ui: SliderControlsUI) {}

    public attach(): void {
        this.ui.prevButton?.addEventListener('click.controls', () => {
            this.ui.element.dispatchEvent(new CustomEvent('previous'));
        });
        this.ui.nextButton?.addEventListener('click.controls', () => {
            this.ui.element.dispatchEvent(new CustomEvent('next'));
        });
        this.ui.dotsContainer?.addDelegateListener('click.controls', '[data-role=dot]', (event) => {
            const page = event.delegateTarget.datamap().getInt('page');
            this.ui.element.dispatchEvent(new CustomEvent<SlideDetails>('goto', {detail: {page: page}}));
        });
    }

    public detach(): void {
        this.ui.prevButton?.removeEventListeners('click.controls');
        this.ui.nextButton?.removeEventListeners('click.controls');
        this.ui.dotsContainer?.removeEventListeners('click.controls');
    }
}
