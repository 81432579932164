import type FilterModel from '@Module/Group/Component/QuickFilter/WebComponent/Model/FilterModel';
import QuickFilterStorage from '@Module/Group/Component/QuickFilter/WebComponent/QuickFilterStorage';
import type QuickFilterUI from '@Module/Group/Component/QuickFilter/WebComponent/QuickFilterUI';

export default class FilterHandler {
    private isAttached = false;
    private quickFilterStorage: QuickFilterStorage;

    constructor(
        private readonly ui: QuickFilterUI,
        private readonly filter: FilterModel,
    ) {
        this.quickFilterStorage = new QuickFilterStorage(window.location.href);
    }

    public attach(): void {
        if (this.isAttached) {
            throw new Error('FilterHandler was already attached.');
        }
        this.isAttached = true;

        this.filter.addChangeListener((newValue: string) => {
            this.ui.inputElement.value = newValue;
            this.ui.element.setAttribute('value', newValue);
            this.ui.element.dispatchEvent(new Event('change', {bubbles: true, cancelable: true}));
            this.quickFilterStorage.setString('filter', newValue);
        });

        this.filter.setFilterText(this.quickFilterStorage.getString('filter', ''));
    }

    public detach(): void {
        if (this.isAttached === false) {
            throw new Error('FilterHandler was not attached.');
        }
        this.isAttached = false;
    }
}
