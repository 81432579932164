import {injectable} from '@123/druid/dist/Framework/Decorators/Inject';
import type AbstractMessage from '@123/druid/dist/Framework/Messaging/AbstractMessage';
import MessageBusDruid from '@123/druid/dist/Framework/Messaging/MessageBus';
import type {IUnsubscribable} from '@123/druid/dist/Types/IUnsubscribable';

@injectable()
export default class MessageBus {
    public emit<T = undefined>(message: AbstractMessage): Promise<T> {
        return MessageBusDruid.emit(message);
    }

    public observe<T extends AbstractMessage, Response = any>(
        messageConstructor: {new (...args: any[]): T},
        handler: (message: T) => Promise<Response> | Response,
    ): IUnsubscribable {
        return MessageBusDruid.observe(messageConstructor, handler);
    }
}
