import debounce from '@123/druid/dist/Utility/Decorator/Debounce';
import type ElementList from '@Component/ElementList/ElementList';
import type {ElementListCallback} from '@Component/ElementList/Type/ElementListCallback';

export default class ElementListUI {
    public elements: HTMLElement[] = [];

    constructor(public readonly element: ElementList) {
        this.elements = Array.from(this.element.queryRoleAll<HTMLElement>('element-list-item'));
    }

    get visibleElements(): HTMLElement[] {
        return this.elements.filter((element) => element.classList.contains('u-hidden') === false);
    }

    get hiddenElements(): HTMLElement[] {
        return this.elements.filter((element) => element.classList.contains('u-hidden'));
    }

    public enableElement(element: HTMLElement, enable: boolean, invertOthers: boolean): void {
        const isCurrentEnabled = element.datamap().has('elementEnabled');
        if (enable === isCurrentEnabled) {
            return;
        }

        if (invertOthers) {
            this.elements.forEach((el: HTMLElement) => {
                if (enable === false) {
                    el.datamap().setString('elementEnabled', 'element-enabled');
                } else {
                    delete el.dataset.elementEnabled;
                }
            });
        }

        if (enable) {
            element.datamap().setString('elementEnabled', 'element-enabled');
        } else {
            delete element.dataset.elementEnabled;
        }

        this.updateElements();
    }

    public setCallbacks(onActivate: ElementListCallback, onDeactivate: ElementListCallback): void {
        this.onActivate = onActivate;
        this.onDeactivate = onDeactivate;
        this.updateElements();
    }

    @debounce(10)
    public updateElements(): void {
        const maxItems = this.element.maxItems;
        let visibleCount = 0;
        this.elements.forEach((element) => {
            const enabled = element.datamap().has('elementEnabled');
            if (enabled === false || (visibleCount >= maxItems && maxItems > -1)) {
                this.onDeactivate(element);
                return;
            }
            this.onActivate(element);
            visibleCount++;
        });

        this.element.dispatchEvent(new CustomEvent('update', {bubbles: true, cancelable: false}));
    }

    private onActivate: ElementListCallback = (element: HTMLElement): void => {
        element.classList.remove('u-hidden');
    };

    private onDeactivate: ElementListCallback = (element: HTMLElement): void => {
        element.classList.add('u-hidden');
    };
}
