import tag from '@123/druid/dist/Framework/Decorators/Tag';
import {maxItems} from '@Component/ElementList/ElementListDefaultValues';
import ElementListUI from '@Component/ElementList/ElementListUI';
import CollapserHandler from '@Component/ElementList/Handler/CollapserHandler';
import type ElementListContext from '@Component/ElementList/Type/ElementListContext';
import AttributeHandler from '@Component/ElementList/AttributeHandler';
import type {ElementListCallback} from '@Component/ElementList/Type/ElementListCallback';

@tag('dr-element-list')
export default class ElementList extends HTMLElement {
    private context?: ElementListContext;

    set maxItems(value: number) {
        this.attributeMap().setInt('max-items', value);
    }

    get maxItems(): number {
        return this.attributeMap().getInt('max-items', maxItems);
    }

    /**
     * All HTMLElements that are in this list, regardless of their state
     */
    get elements(): HTMLElement[] {
        return this.getContext().ui.elements;
    }

    get visibleElements(): HTMLElement[] {
        return this.getContext().ui.visibleElements;
    }

    get hiddenElements(): HTMLElement[] {
        return this.getContext().ui.hiddenElements;
    }

    /**
     * Enables or disables an element in the list. If the element gets a new state because of it, an update event will be fired.
     *
     * @param {HTMLElement} element
     * @param {boolean} [enable=true]
     * @param {boolean} [invertOthers=false]
     */
    public enableElement(element: HTMLElement, enable: boolean = true, invertOthers: boolean = false): void {
        this.getContext().ui.enableElement(element, enable, invertOthers);
    }

    public connectedCallback(): void {
        this.createContext();
        this.dispatchMounted();
    }

    public disconnectedCallback(): void {
        this.getContext().collapser.detachHandler();
    }

    static get observedAttributes(): string[] {
        return AttributeHandler.observedAttributes;
    }

    public setCallbacks(onActivate: ElementListCallback, onDeactivate: ElementListCallback): void {
        this.getContext().ui.setCallbacks(onActivate, onDeactivate);
    }

    public attributeChangedCallback(name: string, _oldValue: string, newValue: string | null): void {
        if (this.context === undefined) {
            // attributeChangedCallback will be called before connected callback on first render.
            // Therefore, we can not use this.getContext() here. It would always throw an error, because at that moment there will never be a context.
            return;
        }
        new AttributeHandler(this.context.ui).handleAttribute(name, newValue);
    }

    private getContext(): ElementListContext {
        if (this.context === undefined) {
            throw new Error('No context found.');
        }

        return this.context;
    }

    private createContext(): void {
        const ui = new ElementListUI(this);
        const collapser = new CollapserHandler(this);

        this.context = {ui, collapser};
    }

    private dispatchMounted(): void {
        this.getContext().ui.element.dispatchEvent(new CustomEvent('mounted'));
    }
}
