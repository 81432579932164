import tag from '@123/druid/dist/Framework/Decorators/Tag';

@tag('dr-element-list-collapser')
export default class ElementListCollapser extends HTMLElement {
    private buttons: HTMLElement[] = [];

    public connectedCallback(): void {
        const buttonShowMore = this.queryRole('show-more');
        const buttonShowLess = this.queryRole('show-less');

        buttonShowMore.addEventListener('click.collapser', () => {
            this.dispatchEvent(new CustomEvent('collapse', {bubbles: true, cancelable: true}));
            this.toggleElementListButtons();
        });
        buttonShowLess.addEventListener('click.collapser', () => {
            this.dispatchEvent(new CustomEvent('uncollapse', {bubbles: true, cancelable: true}));
            this.toggleElementListButtons();
        });

        this.buttons = [buttonShowMore, buttonShowLess];
    }

    public disconnectedCallback(): void {
        this.buttons.forEach((button) => button.removeEventListeners('click.collapser'));
    }

    private toggleElementListButtons(): void {
        this.buttons.forEach((button) => button.classList.toggle('u-hidden'));
    }
}
