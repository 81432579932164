import type QuickFilterUI from '@Module/Group/Component/QuickFilter/WebComponent/QuickFilterUI';

// setting constants for strings that occur often, for improved minification
const placeholder = 'placeholder';
const value = 'value';

export default class AttributeHandler {
    public static readonly observedAttributes = [placeholder, value];

    constructor(private readonly ui: QuickFilterUI) {}

    public handleAttribute(name: string, newValue: string | null): void {
        switch (name) {
            case placeholder:
                this.ui.inputElement.setAttribute(placeholder, newValue ?? '');
                break;
            case value:
                this.ui.element.value = newValue ?? '';
                break;
        }
    }
}
